import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Tour, Typography } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import box from '../../assets/icons/box_icon.png';
import dayNight from '../../assets/icons/day_night_icon.png';
import deleteIcon from '../../assets/icons/delete_icon.png';
import pan from '../../assets/icons/pan_icon.png';
import search from '../../assets/icons/search_icon.png';
import arrow from '../../assets/icons/arrow_icon.png';
import measure from '../../assets/icons/measure_icon.png';
import ruler from '../../assets/icons/ruler_icon.png';

const { Title } = Typography;

const Case = () => {
  const [form] = Form.useForm();
  const maximizeButton = useRef(null);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [open, setOpen] = useState(false);

  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);
  document.addEventListener('MSFullscreenChange', exitHandler);

  useEffect(() => {
    setOpen(true);
  }, []);

  function exitHandler() {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      setIsFullScreen(false);
    }
  }

  const handleFullSCreen = () => {
    if (isFullScreen) {
      closeFullscreen();
      setIsFullScreen(false);
    } else {
      openFullScreen();
      setIsFullScreen(true);
    }
  };

  const openFullScreen = () => {
    let elem = document.getElementById('rou');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  const steps = [
    {
      title: 'Maximize Image',
      description: 'Click here to view the images in full screen mode. Press escape to return to the original size.',
      target: () => maximizeButton.current,
      placement: 'bottomRight',
    },
  ];

  return (
    <>
      <Tour open={open} onClose={() => setOpen(false)} steps={steps} />

      <Row>
        <Col xs={24}>
          <div id='rou'>
            <Row justify='space-between'>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
                <Title level={4} style={{ color: '#fff', padding: `${isFullScreen ? '1em 0 0 1em' : '0 0 0 1em'}` }}>
                  Remaining Time: 2:59:59
                </Title>
              </div>
              <div>
                {isFullScreen ? (
                  <FullscreenExitOutlined onClick={handleFullSCreen} style={{ fontSize: '2em', padding: '1em 1em 0 1em' }} />
                ) : (
                  <FullscreenOutlined ref={maximizeButton} onClick={handleFullSCreen} style={{ fontSize: '2em', padding: '0 0 0 1em' }} />
                )}
              </div>
            </Row>
            <Row>
              <Col xs={24} md={16}>
                <embed
                  src='https://dc83kd865gmmm.cloudfront.net/viewer/dicomjson?url=https://api.radme.org/api/v1/question/dicom/323973'
                  style={{ width: '100%', height: `${isFullScreen ? '92vh' : '71vh'}` }}
                />
              </Col>
              <Col xs={24} md={8} style={{ marginTop: '2em' }}>
                <ol style={{ fontSize: '1.2em' }}>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Change CT windows:</span> click <img src={dayNight} width='25px' height='25px' alt='daynight' /> and select a window or for
                      example, press 0 for the bone window.
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Zoom:</span> click <img src={search} alt='search' width='25px' height='25px' /> or hold right click while moving the mouse.
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Pan:</span> click <img src={pan} alt='pan' width='25px' height='25px' /> or hold middle click while moving the mouse.
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Measure attenuation:</span> click <img src={measure} alt='measure' width='25px' height='25px' /> or press "e"
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Draw arrows:</span> click <img src={arrow} alt='arrow' width='25px' height='25px' /> or press "a"
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Ruler:</span> click <img src={ruler} alt='ruler' width='25px' height='25px' /> or press "r"
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Delete arrows, measurements, or other markings:</span> click <img src={deleteIcon} alt='delete' width='25px' height='25px' />{' '}
                      or press “c”
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Change series:</span> drag the head CT into the center.
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>View multiple series:</span> click <img src={box} alt='box' width='25px' height='25px' /> and select two boxes. Drag the Head
                      CT into the empty grid.
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      <span style={{ color: '#FC6C64', fontWeight: 500 }}>Maximize screen: </span> <FullscreenOutlined style={{ fontSize: '1.5em' }} />
                    </Title>
                  </li>
                  <li>
                    <Title level={5} style={{ color: '#fff' }}>
                      The findings are provided, however, please type this into the impression box: “Normal eyes. Concern for stent failure given severe left hydronephrosis.”
                    </Title>
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Form form={form} scrollToFirstError>
            <Row justify='space-between'>
              <Link to='/tutorial/history'>
                <Button size='large' type='primary' style={{ marginTop: '2em' }} icon={<ArrowLeftOutlined />}>
                  History
                </Button>
              </Link>

              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <label style={{ color: '#CBC', fontSize: '20px' }} htmlFor='findings'>
                    Findings
                  </label>
                  <Form.Item name='findings'>
                    <Input.TextArea
                      rows={1}
                      autoSize
                      defaultValue='Normal orbits. Left kidney with J-J stent and severe hydronephrosis.'
                      size='large'
                      placeholder='Type answer here'
                      style={{ minWidth: '400px' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <label style={{ color: '#CBC', fontSize: '20px' }} htmlFor='impression'>
                    Impression
                  </label>
                  <Form.Item name='impression'>
                    <Input.TextArea rows={1} autoSize size='large' placeholder='Type answer here' style={{ minWidth: '400px' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Link to='/tutorial/review'>
                <Button size='large' type='primary' htmlType='submit' style={{ marginTop: '2em' }}>
                  Review Exam <ArrowRightOutlined />
                </Button>
              </Link>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Case;

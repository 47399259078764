import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { BaseAPI } from '../utils/ApiConfig';
import ErrorHandler from '../utils/ErrorHandler';

const queryOptions = {
  onError: ErrorHandler,
};

export const AdminLogin = () =>
  useMutation((body) => BaseAPI.post('auth/signin', body), {
    ...queryOptions,
  });

export const NewCaseStudy = () =>
  useMutation(
    (body) =>
      BaseAPI.post('question/admin/create', body, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      }),
    {
      ...queryOptions,
    }
  );

export const UploadDiComFiles = () =>
  useMutation(
    (body) =>
      BaseAPI.post(`question/admin/dicom/${sessionStorage.getItem('questionId')}`, body, {
        timeout: 180000,
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}`, 'content-type': 'multipart/form-data' },
      }),
    {
      ...queryOptions,
    }
  );

export const GetCaseStudyList = () =>
  useQuery(
    ['case-study-list'],
    () =>
      BaseAPI.get('question/admin/list', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      }),
    {
      select: (data) => data?.data?.data?.map((el) => ({ ...el, key: crypto.randomUUID() })),
      ...queryOptions,
    }
  );

export const DeleteCaseStudy = () => {
  const queryClient = useQueryClient();

  return useMutation((body) => BaseAPI.patch('question/admin/delete', body, { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['case-study-list']);
    },
    ...queryOptions,
  });
};

export const GetQuestionListByTest = (id) =>
  useQuery(
    ['question-by-test', id],
    () =>
      BaseAPI.get(`question/admin/answer/list/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      }),
    {
      select: (data) => data?.data?.data,
      ...queryOptions,
    }
  );

export const GetSingleCaseStudy = (id) =>
  useQuery(
    ['case-study', id],
    () =>
      BaseAPI.get(`question/admin/list/${id}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      }),
    {
      select: (data) => data?.data?.data,
      ...queryOptions,
    }
  );

export const GetTestListForAdmin = () =>
  useQuery(
    ['test-list-admin'],
    () =>
      BaseAPI.get('/skill-test/list', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
      }),
    {
      select: (data) => data?.data?.data,
      ...queryOptions,
    }
  );

export const CreateSkillTest = () => useMutation((body) => BaseAPI.post('/skill-test/create', body), { ...queryOptions });

export const ResumeLastExam = () => useMutation((body) => BaseAPI.post('/skill-test/resume', body), { ...queryOptions });

export const GetSingleCaseStudyForStudent = () => useMutation((body) => BaseAPI.post('/question/details', body), { ...queryOptions });

export const EndTheExam = () => useMutation((body) => BaseAPI.post('/skill-test/submit', body), { ...queryOptions });

export const SaveCurrentCase = () => {
  const queryClient = useQueryClient();

  return useMutation((body) => BaseAPI.patch('/skill-test/save', body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-test-cases']);
    },
    ...queryOptions,
  });
};

export const SyncCaseStudiesWithTime = (test_id, time) =>
  useQuery(['sync-case-study', test_id], () => BaseAPI.get(`/skill-test/sync/${test_id}/${time}`), {
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
  });

export const GetTestCasesForReviewExam = (id) =>
  useQuery(['get-test-cases', id], () => BaseAPI.get(`/question/list/${id}`), { select: (data) => data?.data?.data, refetchOnWindowFocus: true, refetchOnMount: true, cacheTime: 0, ...queryOptions });
